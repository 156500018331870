import {createWebHistory, createRouter} from 'vue-router';
import {Overview, CustomerMap} from './pages';

/*  
  The preferred use is a sub-router.
*/

export const routes = [
  {
    path: '/',
    name: 'customer-manager-overview',
    component: () => import('./pages/CustomerOverview/CustomerOverview.vue'),
  },
  {
    path: '/customermap/:id/:customerName?',
    name: 'customer-manager-customermap',
    component: () => import('./pages/CustomerMap/CustomerMap.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

import { CustomerType } from '../app/api-generated/enums';
import { schemas } from '@dnx/core';
import { z } from '@dnx/core';
const { phone, name, houseNumber, houseNumberAdd, street, zipCode, referenceNumber, city, chamberOfCommerce, vat, iban, website, initials, } = schemas;
export const privateCustomer = z.object({
    type: z.literal(CustomerType.Private),
    divisionId: z.string(),
    firstName: name.nullish(),
    prefix: name.nullish(),
    initials: initials.trim().nullish(),
    lastName: name.nullish(),
    birthDate: z.string().nullish(),
    houseNo: houseNumber.nullish(),
    houseNoAdd: houseNumberAdd.nullish(),
    street: street.nullish(),
    zipCode: zipCode.nullish(),
    referenceNumber: referenceNumber.nullish(),
    city: city.nullish(),
    phoneNumber: phone.nullish(),
    email: z.string().email().nullish(),
    remarks: z.string().nullish(),
});
export const businessCustomer = z.object({
    type: z.literal(CustomerType.Business),
    divisionId: z.string(),
    companyName: name.nullish(),
    legalForm: z.string().nullish(),
    vatNumber: vat.nullish(),
    chamberOfCommerceNumber: chamberOfCommerce.nullish(),
    iban: iban.nullish(),
    website: website.nullish(),
    street: street.nullish(),
    houseNo: houseNumber.nullish(),
    houseNoAdd: houseNumberAdd.nullish(),
    zipCode: zipCode.nullish(),
    referenceNumber: referenceNumber.nullish(),
    city: city.nullish(),
    phoneNumber: phone.nullish(),
    email: z.string().email().nullish(),
    remarks: z.string().nullish(),
});

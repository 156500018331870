var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import CustomerEditForm from '../../components/CustomerEditForm.vue';
import { DateTime } from 'luxon';
import { mapActions, mapGetters, mapMutations, useStore } from 'vuex';
import { computed, defineComponent, reactive, ref, triggerRef } from 'vue';
import { T, useDnxServerTableList, addToast } from '@dnx/core';
import customersApi from '../../../app/api-generated/customerManager';
let fetchCustomersTimeoutId;
export default defineComponent({
    name: 'CustomerOverview',
    components: {
        CustomerEditForm,
    },
    setup() {
        const store = useStore();
        const fetchDivisions = () => store.dispatch('areas/fetchSummaries');
        const companyCustomerFilterFieldsData = {
            companyname: {
                label: T('CUSTOMER_MANAGER_FILTER_COMPANYNAME'),
            },
            coc: {
                label: T('CUSTOMER_MANAGER_FILTER_COC'),
            },
            phone: { label: T('CUSTOMER_MANAGER_FILTER_PHONENUMBER') },
            legalform: {
                label: T('CUSTOMER_MANAGER_FILTER_LEGALFORM'),
                options: [],
                type: 'select',
            },
            email: { label: T('CUSTOMER_MANAGER_FILTER_EMAIL') },
            street: { label: T('CUSTOMER_MANAGER_FILTER_STREET') },
            zipcode: { label: T('CUSTOMER_MANAGER_FILTER_ZIPCODE') },
            city: { label: T('CUSTOMER_MANAGER_FILTER_CITY') },
        };
        const privateCustomerFilterFieldsData = {
            firstname: { label: T('CUSTOMER_MANAGER_FILTER_FIRSTNAME') },
            lastname: { label: T('CUSTOMER_MANAGER_FILTER_LASTNAME') },
            email: { label: T('CUSTOMER_MANAGER_FILTER_EMAIL') },
            street: { label: T('CUSTOMER_MANAGER_FILTER_STREET') },
            zipcode: { label: T('CUSTOMER_MANAGER_FILTER_ZIPCODE') },
            city: { label: T('CUSTOMER_MANAGER_FILTER_CITY') },
            divisionId: { label: T('CUSTOMER_MANAGER_FILTER_DIVISIONID'), options: [], type: 'treeSelect' },
            externalcustomerid: { label: T('CUSTOMER_MANAGER_OVERVIEW_EXTERNALCUSTOMERID') },
        };
        const customerTypes = {
            private: {
                getter: customersApi.searchCustomers,
                fields: reactive(privateCustomerFilterFieldsData),
            },
            business: {
                getter: customersApi.searchCompanies,
                fields: reactive(companyCustomerFilterFieldsData),
            },
        };
        const urgentCustomerFilters = ref([]);
        const urgentCustomers = useDnxServerTableList(customersApi.getTableUrgentData, urgentCustomerFilters, {
            pageSize: 5,
        });
        const newCustomerFilters = ref([]);
        const newCustomers = useDnxServerTableList(customersApi.getTableNewCustomersData, newCustomerFilters, {
            pageSize: 5,
        });
        const activeFilterMenuItem = ref('private');
        const customerSearchStates = Object.fromEntries(Object.entries(customerTypes).map(entry => {
            const key = entry[0];
            const searchQueries = ref([]);
            const inputs = ref(new Map());
            const filters = computed(() => {
                return [
                    {
                        simpleSearch: searchQueries.value,
                        columns: Array.from(inputs.value.entries()).map(entry => {
                            return { name: entry[0], search: entry[1] };
                        }),
                    },
                ];
            });
            const tableProps = useDnxServerTableList(entry[1].getter, filters, {
                pageSize: 20,
                debounce: 300,
            });
            const columns = computed(() => { var _a; return ((_a = tableProps.value.data) === null || _a === void 0 ? void 0 : _a.columns) || []; });
            const rows = computed(() => { var _a; return ((_a = tableProps.value.data) === null || _a === void 0 ? void 0 : _a.rows) || []; });
            return [
                key,
                {
                    searchQueries,
                    tableProps,
                    columns,
                    rows,
                    fields: entry[1].fields,
                    inputs,
                    filters,
                    setFilter: (filterKey, value) => {
                        if (value) {
                            searchQueries.value = [];
                            inputs.value.set(filterKey, value);
                        }
                        else {
                            inputs.value.delete(filterKey);
                        }
                    },
                    setSearchQueries: (value) => {
                        if (!searchQueries.value.length && !value.length)
                            return;
                        inputs.value.clear();
                        searchQueries.value = value;
                    },
                },
            ];
        }));
        customersApi.legalForms().then(response => {
            if (!response.success)
                return;
            customerSearchStates.business.fields.legalform.options = response.value.map(value => ({ value, label: T(value) }));
        });
        (() => __awaiter(this, void 0, void 0, function* () {
            yield fetchDivisions();
            customerSearchStates.private.fields.divisionId.options = store.getters['areas/getSummaries'];
        }))();
        const showEditForm = ref(false);
        const onCustomerSaved = () => {
            showEditForm.value = false;
            addToast('success', T('CUSTOMER_CONFIRM_SAVED'));
            triggerRef(customerSearchStates[activeFilterMenuItem.value].filters);
            triggerRef(urgentCustomerFilters);
            triggerRef(newCustomerFilters);
        };
        return {
            urgentCustomers,
            newCustomers,
            privateFilterFieldsData: privateCustomerFilterFieldsData,
            businessFilterFieldsData: companyCustomerFilterFieldsData,
            newCustomerFilters,
            urgentCustomerFilters,
            customerSearchStates,
            activeFilterMenuItem,
            onCustomerSaved,
            showEditForm,
        };
    },
    data() {
        const data = {
            widgetColors: ['#1D5F90', '#2783C6', '#319AE8'],
            selectedPeriod: null,
            showEditForm: false,
            tableCustomerOverviewVisibleColumnsList: [],
            advancedPrivateFilterInputs: Object.fromEntries(Object.keys(this.privateFilterFieldsData).map(key => [key, undefined])),
            advancedBusinessFilterInputs: Object.fromEntries(Object.keys(this.businessFilterFieldsData).map(key => [key, undefined])),
            // for version 1 use the default date from today
            todayInIso: DateTime.now().toJSDate().toISOString(),
            accessibleCustomerTypes: [],
        };
        return data;
    },
    computed: Object.assign(Object.assign({}, mapGetters({
        allCounterWidgets: 'customerManager/getAllCounterWidgets',
        counterWidgetKeys: 'customerManager/getCounterWidgetKeys',
        tableCustomerOverviewData: 'customerManager/getTableCustomerOverviewData',
        preferredLanguage: 'locale/preferredLanguage',
        isLoadingCustomers: 'customerManager/getIsLoadingCustomers',
        legalForms: 'customerManager/getLegalForms',
    })), { visibleColumnsTableCustomerOverviewData() {
            // Find only the visible columns where value is true
            const visibleColumns = this.tableCustomerOverviewVisibleColumnsList
                .filter(item => item.value)
                .map(item => ({
                displayName: item.label,
                propertyName: item.propertyName,
            }));
            return visibleColumns;
        },
        searchType() {
            if (!this.accessibleCustomerTypes.length)
                return undefined;
            if (this.accessibleCustomerTypes.length == 1)
                return this.accessibleCustomerTypes[0];
            return this.activeFilterMenuItem;
        },
        newCustomerRows() {
            if (!this.newCustomers.data) {
                return [];
            }
            const setTimeToMidnight = (dateString) => {
                if (dateString === undefined)
                    return undefined;
                const date = new Date(dateString);
                date.setHours(0, 0, 0, 0);
                return date.toISOString();
            };
            return this.newCustomers.data.rows.map((row) => {
                return Object.assign(Object.assign({}, row), { date: setTimeToMidnight(row.date) });
            });
        } }),
    watch: {
        allCounterWidgets(newVal, oldVal) {
            if (newVal) {
                this.collectWidgetData();
            }
        },
        'tableCustomerOverviewData.columns': {
            handler(newVal) {
                {
                    this.makeTableCustomerOverviewVisibleColumnsList(newVal);
                }
            },
        },
        legalForms(newVal) {
            this.businessFilterFieldsData.legalform.options = newVal.map(name => ({
                label: name,
                value: name,
            }));
        },
    },
    created() {
        if (this.$can.read.privatecustomers) {
            this.accessibleCustomerTypes.push('private');
        }
        if (this.$can.read.businesscustomers) {
            this.accessibleCustomerTypes.push('business');
        }
        this.fetchAllCounterWidgets();
        // this.fetchTableUrgentData(this.tableUrgentDataSettings);
        // this.fetchTableNewCustomersData(this.tableNewCustomersDataSettings);
        if (this.tableCustomerOverviewData.columns && this.tableCustomerOverviewVisibleColumnsList.length < 1) {
            this.makeTableCustomerOverviewVisibleColumnsList(this.tableCustomerOverviewData.columns);
        }
    },
    methods: Object.assign(Object.assign(Object.assign({}, mapActions({
        fetchAllCounterWidgets: 'customerManager/fetchAllCounterWidgets',
        fetchCounterWidget: 'customerManager/fetchCounterWidget',
        fetchTableCustomerOverviewData: 'customerManager/fetchTableCustomerOverviewData',
        triggerAlertMessage: 'notifications/triggerAlertMessage',
    })), mapMutations({
        setCustomer: 'customerManager/setCustomer',
    })), { collectWidgetData() {
            if (this.allCounterWidgets) {
                // Only continue if not all the single counterWidgets are loaded yet
                if (Object.keys(this.counterWidgetKeys).length < this.allCounterWidgets.length) {
                    // Get the single counterWidgets
                    this.allCounterWidgets.forEach(widget => {
                        this.fetchCounterWidget({
                            id: widget.id,
                            periodStart: this.todayInIso,
                            periodEnd: this.todayInIso,
                        });
                    });
                }
            }
        },
        toSelectedRowDataPage(row) {
            this.$router.push(`/customer-manager/customermap/${row.id}`);
        },
        makeTableCustomerOverviewVisibleColumnsList(columns) {
            const list = (columns === null || columns === void 0 ? void 0 : columns.map(item => ({
                label: T(item.displayName),
                value: true,
                propertyName: item.propertyName,
            }))) || [];
            this.tableCustomerOverviewVisibleColumnsList = list;
        },
        translateWidgetData(topics) {
            if (!topics) {
                return [];
            }
            const translateWidgetToPreferedLanguage = topics ? T(topics.toUpperCase()) : '-';
            return translateWidgetToPreferedLanguage;
        },
        translateColumnData(columns) {
            if (!columns) {
                return [];
            }
            const translateColumnToPreferedLanguage = columns.map(column => (Object.assign(Object.assign({}, column), { displayName: column.propertyName ? T(`CUSTOMER_MANAGER_OVERVIEW_${column.propertyName.toUpperCase()}`) : '-' })));
            return translateColumnToPreferedLanguage;
        } }),
});

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class CustomerManagerDetails {
    constructor() {
        this.paths = Object.freeze({
            getSections: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Sections`,
            getScores: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Section/Scores`,
            getProfileInformation: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Section/ProfileInformation`,
            getMiscellaneous: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Section/Miscellaneous`,
            getTimelineData: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Section/Timeline`,
            getTimelineEventTypes: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Section/TimelineEventTypes`,
            getChecklistData: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Section/Checklist`,
            getLastPurchaseData: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Section/LastPurchases`,
            createCustomEvent: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Event`,
            updateCustomEvent: (id, customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Event/${encodeURIComponent(id)}`,
            deleteCustomEvent: (id, customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Event/${encodeURIComponent(id)}`,
        });
    }
    /**
     * CustomerManagerDetails.GetSections "GET api/CustomerManager/{customerId}/Details/Sections"
     *
     * Get the visible section buildup for the current customer visible to the current user
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerSectionDefinitionsModel>>} Object describing visible sections
     */
    getSections(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Sections`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.GetScores "GET api/CustomerManager/{customerId}/Details/Section/Scores"
     *
     * Read all simple statistics for the current customer
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerScoreDataResponseModel[]>>} Collection of simple statistics for the current customer
     */
    getScores(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Section/Scores`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.GetProfileInformation "GET api/CustomerManager/{customerId}/Details/Section/ProfileInformation"
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerProfileResponseModel>>}
     */
    getProfileInformation(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Section/ProfileInformation`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.GetMiscellaneous "GET api/CustomerManager/{customerId}/Details/Section/Miscellaneous"
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerProfileResponseModel>>}
     */
    getMiscellaneous(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Section/Miscellaneous`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.GetTimelineData "GET api/CustomerManager/{customerId}/Details/Section/Timeline"
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerTimelineItemResponseModel[]>>}
     */
    getTimelineData(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Section/Timeline`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.GetTimelineEventTypes "GET api/CustomerManager/{customerId}/Details/Section/TimelineEventTypes"
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<{ [key: string]: CustomerEventTypeModel[] }>>}
     */
    getTimelineEventTypes(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Section/TimelineEventTypes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.GetChecklistData "GET api/CustomerManager/{customerId}/Details/Section/Checklist"
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerChecklistItemResponseModel[]>>}
     */
    getChecklistData(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Section/Checklist`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.GetLastPurchaseData "GET api/CustomerManager/{customerId}/Details/Section/LastPurchases"
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerLastPurchaseResponseModel[]>>}
     */
    getLastPurchaseData(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Section/LastPurchases`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.CreateCustomEvent "POST api/CustomerManager/{customerId}/Details/Event"
     * @param {CustomerCustomEventModel} model
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    createCustomEvent(model, customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Event`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.UpdateCustomEvent "PUT api/CustomerManager/{customerId}/Details/Event/{id}"
     * @param {Guid} id
     * @param {CustomerCustomEventModel} model
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    updateCustomEvent(id, model, customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Event/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerDetails.DeleteCustomEvent "DELETE api/CustomerManager/{customerId}/Details/Event/{id}"
     * @param {Guid} id
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    deleteCustomEvent(id, customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Event/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const customerManagerDetails = new CustomerManagerDetails();
export { customerManagerDetails as default, CustomerManagerDetails };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
class CustomerManagerProfileActions {
    constructor() {
        this.paths = Object.freeze({
            getProfileActions: (customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Actions/ProfileActions`,
            invokeAction: (name, customerid) => `api/CustomerManager/${encodeURIComponent(customerid)}/Details/Actions/ProfileActions/${encodeURIComponent(name)}`,
        });
    }
    /**
     * CustomerManagerProfileActions.GetProfileActions "GET api/CustomerManager/{customerId}/Details/Actions/ProfileActions"
     *
     * Obtain a list of all available profile actions for the given customer
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ProfileActionListModel[]>>} List of available profile actions
     */
    getProfileActions(customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Actions/ProfileActions`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManagerProfileActions.InvokeAction "POST api/CustomerManager/{customerId}/Details/Actions/ProfileActions/{name}"
     *
     * Invoke the action with the given name using the given par
     * @param {string} name Name of the custom action to invoke
     * @param {unknown} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ProfileActionResponseModel>>} Response indicating success, may optionally include message
     */
    invokeAction(name, customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}/Details/Actions/ProfileActions/${name === undefined || name === null ? "" : encodeURIComponent(name)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const customerManagerProfileActions = new CustomerManagerProfileActions();
export { customerManagerProfileActions as default, CustomerManagerProfileActions };

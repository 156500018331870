import 'systemjs-webpack-interop/auto-public-path';

import {lifecycle} from './app.js';
import {routes} from './router';
import {Overview} from './pages';
import CustomerManagerModule from './stores/customerManager';

// To run the app as a standalone, use the app, otherwise return the lifecycle
document.addEventListener(
  'dnx:vuex-ready',
  evt => evt.detail.vuex.registerModule('customerManager', CustomerManagerModule),
  {once: true}
);

// singleSpa & mountParcel also in props
function createManifest() {
  const {name, version, description, dnx} = require('../package.json');
  const defaults = {
    color: '#ff0000',
    icon: 'gear',
  };

  let manifest = {
    name,
    version,
    description,
    ...defaults,
    ...dnx,
  };
  return manifest;
}

const manifest = createManifest();
export default manifest;

// handle notification bridge registration
const bridge = {
  name: manifest.name,
  bootstrap: async props => {
    const {name, singleSpa} = props;
  },
  mount: async props => {
    const {interop} = props;
    interop?.register(bridge);
  },
  unmount: async props => {
    const {interop} = props;
    interop?.unregister(bridge);
  },
};

// handle app registration
const core = {
  mount: async props => {
    const {name, mountParcel, customProps} = props;
    for (const [key, value] in customProps) manifest[key] = value;
    manifest.csscolor = `var(--module-${manifest.name}, ${manifest.color})`;
    await mountParcel(bridge, {domElement: document.body, interop: Window.interop});

    Window._shellReady.then(() => {
      // expose routes
      document.dispatchEvent(
        new CustomEvent('dnx:app-loaded', {
          detail: {
            appmodule: name,
            config: {
              component: Overview,
              routes,
            },
          },
        })
      );
    });
  },
};

export const bootstrap = lifecycle.bootstrap;

export const mount = [core.mount, lifecycle.mount];

export const update = lifecycle.update;

export const unmount = lifecycle.unmount;

export const unload = lifecycle.unload ?? (() => Promise.resolve());

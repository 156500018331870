<template>
  <template v-if="generalInformation && sections && customer">
    <XHeaderBox
      iconName="customers"
      iconColor="default"
      iconBackground="#2783C6"
      titleColor="#2783C6"
      :headerTitle="$t('CUSTOMER_MANAGER_HEADING')"
      :breadcrumbs="[
        {
          name: 'customer-manager-overview',
          label: $t('CUSTOMER_MANAGER_HEADING'),
        },
        {
          name: 'customer-manager-customermap',
          params: {id: $route.params.id},
          label: generalInformation.name,
        },
      ]"
    >
      <template #rightButtonSlot>
        <CustomerProfileActions :customer-id="customerId" />
      </template>
    </XHeaderBox>
    <XWrapper>
      <XStack direction="vertical" gap="lg">
        <XStack direction="vertical" gap="lg">
          <CustomerProfile v-if="sections.showProfileInformation" />
          <CustomerTimeline v-if="sections.showTimeline" :showContactInformation="sections.showContactInformation" />
          <CustomerChecklist v-if="sections.showChecklist" />
          <CustomerRequiredData />
          <!-- Not needed in the nearby future -->
          <!-- <CustomerLastPurchased v-if="sections.showLastPurchases" /> -->
          <XStack v-if="selectedMenuItem == 1">
            <CustomerContacts v-if="sections.showContactInformation" />
            <div></div>
          </XStack>
        </XStack>
      </XStack>
    </XWrapper>
  </template>
</template>

<script>
import CustomerProfile from './CustomerProfile.vue';
import CustomerTimeline from './CustomerTimeline.vue';
import CustomerLastPurchased from './CustomerLastPurchased.vue';
import CustomerChecklist from './CustomerChecklist.vue';
import CustomerContacts from './CustomerContacts.vue';
import CustomerRequiredData from './CustomerRequiredData.vue';
import CustomerProfileActions from './CustomerProfileActions.vue';
import CustomerMiscEditor from './CustomerMiscEditor.vue';
import customerApi from '../../../app/api-generated/customers';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {T} from '@dnx/core';

export default {
  name: 'CustomerMap',
  components: {
    CustomerProfile,
    CustomerTimeline,
    CustomerLastPurchased,
    CustomerChecklist,
    CustomerContacts,
    CustomerMiscEditor,
    CustomerRequiredData,
    CustomerProfileActions,
  },
  computed: {
    ...mapGetters({
      customer: 'customerManager/getCustomer',
      sections: 'customerManager/getSections',
      selectedMenuItem: 'customerManager/getSelectedMenuItem',
      generalInformation: 'customerManager/getGeneralInformation',
    }),
  },
  async created() {
    const customerId = this.$route.params.id;
    this.customerId = customerId;
    const promise = this.fetchSections(customerId);

    customerApi.get(customerId).then(response => {
      if (response.success) {
        this.setCustomer({id: this.$route.params.id, ...response.value});
      } else {
        this.$router.push({path: '/customer-manager'});
        this.triggerAlertMessage({
          type: 'error',
          message: T('ERROR_CUSTOMER_NOT_FOUND'),
        });
        return;
      }
    });

    if (!(await promise)) {
      this.triggerAlertMessage({
        type: 'error',
        message: T('ERROR_CUSTOMER_NOT_FOUND'),
      });
      try {
        if (window.history.state && window.history.state.back) {
          this.$router.back();
        } else {
          this.$router.push({path: '/customer-manager'});
        }
      } catch {
        this.$router.push({path: '/customer-manager'});
      }
    }
  },
  unmounted() {
    this.clearCustomer();
  },

  methods: {
    ...mapActions({
      fetchSections: 'customerManager/fetchSections',
      triggerAlertMessage: 'notifications/triggerAlertMessage',
    }),
    ...mapMutations({
      setCustomer: 'customerManager/setCustomer',
      clearCustomer: 'customerManager/reset',
    }),
  },
};
</script>

<style lang="scss" scoped>
:deep.wrapper {
  padding-bottom: 1.25rem;
  overflow-x: visible;
}

:deep.nav {
  z-index: 0;
}
</style>

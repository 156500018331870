import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XButton = _resolveComponent("XButton");
    return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.profileActions, (profileAction) => {
        return (_openBlock(), _createBlock(_component_XButton, {
            onClick: profileAction.invoke,
            state: profileAction.state,
            variant: "outlined"
        }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.T(profileAction.buttonLabel)), 1)
            ]),
            _: 2
        }, 1032, ["onClick", "state"]));
    }), 256));
}

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-1c77a11a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "content-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XHeading = _resolveComponent("XHeading");
    const _component_XCheckbox = _resolveComponent("XCheckbox");
    const _component_XMultiBox = _resolveComponent("XMultiBox");
    return (_ctx.selectedMenuItem == 4 && $data.checkedItems && $data.checkedItems.length)
        ? (_openBlock(), _createBlock(_component_XMultiBox, {
            key: 0,
            class: "checklist-container",
            boxLayout: [
                {
                    name: 'content',
                    width: '100%',
                    contentDisplayType: 'block',
                },
            ],
            containerHeight: "initial"
        }, {
            contentHeaderTitle: _withCtx(() => [
                _createVNode(_component_XHeading, { level: "h4" }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CUSTOMER_MANAGER_CHECKLIST')), 1)
                    ]),
                    _: 1
                })
            ]),
            contentBody: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.checkedItems, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: item.displayName,
                            class: "single-check-container",
                            style: _normalizeStyle(item.isChecked && { background: '#EDF3F8' })
                        }, [
                            _createVNode(_component_XCheckbox, {
                                label: item.displayName,
                                modelValue: item.isChecked,
                                "onUpdate:modelValue": ($event) => ($data.checkedItems[index].isChecked = $event)
                            }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
                        ], 4));
                    }), 128))
                ])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}

<template>
  <div class="container as-app-customer-manager">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'customer-manager',
};
</script>

<style scoped>
.container {
  margin-left: var(--navbar-width);
}
</style>

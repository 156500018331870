import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-7de1b25b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "new-event" };
const _hoisted_2 = { class: "section type" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "section-content" };
const _hoisted_5 = { class: "section details" };
const _hoisted_6 = { class: "title" };
const _hoisted_7 = { class: "section-content" };
const _hoisted_8 = { class: "section notations" };
const _hoisted_9 = { class: "title" };
const _hoisted_10 = { class: "section-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XParagraph = _resolveComponent("XParagraph");
    const _component_XCascader = _resolveComponent("XCascader");
    const _component_XInput = _resolveComponent("XInput");
    const _component_XDatePicker = _resolveComponent("XDatePicker");
    const _component_XSelect = _resolveComponent("XSelect");
    const _component_XTextarea = _resolveComponent("XTextarea");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_XParagraph, { color: "var(--text-secondary, #083856)" }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CUSTOMER_MANAGER_TYPE')), 1)
                    ]),
                    _: 1
                })
            ]),
            _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_XCascader, {
                    placeholder: _ctx.$t('CUSTOMER_MANAGER_EVENT_TYPE'),
                    options: _ctx.store.getters['customerManager/timelineEventTypesToTreeSelect'],
                    modelValue: _ctx.state.eventTypeId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.state.eventTypeId) = $event)),
                    disabled: _ctx.isDisabled
                }, null, 8, ["placeholder", "options", "modelValue", "disabled"])
            ])
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_XParagraph, { color: "var(--text-secondary, #083856)" }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CUSTOMER_MANAGER_DETAILS')), 1)
                    ]),
                    _: 1
                })
            ]),
            _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_XInput, {
                    placeholder: _ctx.$t('CUSTOMER_MANAGER_EVENT_TITLE'),
                    modelValue: _ctx.state.displayName,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.state.displayName) = $event)),
                    disabled: _ctx.isDisabled
                }, null, 8, ["placeholder", "modelValue", "disabled"]),
                _createVNode(_component_XDatePicker, {
                    style: { "width": "100%" },
                    label: _ctx.$t('CUSTOMER_MANAGER_EVENT_DATE'),
                    type: "datetime",
                    modelValue: _ctx.date,
                    disabled: _ctx.isDisabled,
                    onUpdateValues: _cache[2] || (_cache[2] = ($event) => (_ctx.date = $event.startDate))
                }, null, 8, ["label", "modelValue", "disabled"]),
                (_ctx.showContactInformation)
                    ? (_openBlock(), _createBlock(_component_XSelect, {
                        key: 0,
                        clearable: true,
                        placeholder: _ctx.$t('CUSTOMER_MANAGER_CONTACTS'),
                        options: _ctx.store.getters['customerManager/getContacts'],
                        modelValue: _ctx.state.contactId,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.state.contactId) = $event)),
                        disabled: _ctx.isDisabled
                    }, null, 8, ["placeholder", "options", "modelValue", "disabled"]))
                    : _createCommentVNode("", true)
            ])
        ]),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_XParagraph, { color: "var(--text-secondary, #083856)" }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CUSTOMER_MANAGER_REMARKS_TITLE')), 1)
                    ]),
                    _: 1
                })
            ]),
            _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_XTextarea, {
                    placeholder: _ctx.$t('CUSTOMER_MANAGER_REMARK'),
                    modelValue: _ctx.state.description,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.state.description) = $event)),
                    disabled: _ctx.isDisabled
                }, null, 8, ["placeholder", "modelValue", "disabled"])
            ])
        ])
    ]));
}

<template>
  <XHeaderBox
    iconName="customers"
    iconColor="default"
    iconBackground="var(--module-customer-manager, #2783C6)"
    titleColor="var(--module-customer-manager, #2783C6)"
    :headerTitle="$t('CUSTOMER_MANAGER_HEADING')"
  />
  <XWrapper>
    <div class="trend-widgets">
      <XTrendWidget
        v-for="(widget, index) in allCounterWidgets"
        :key="`${widget.id}-${index}`"
        :color="widgetColors[index]"
        :totalTitle="$t('TRENDWIDGET_TOTAL')"
        :locale="preferredLanguage"
        :value="counterWidgetKeys[widget.id] ? counterWidgetKeys[widget.id].value : 0"
        :valueOverPeriod="counterWidgetKeys[widget.id] ? counterWidgetKeys[widget.id].valueOverPeriod : 0"
        :trendType="translateWidgetData(widget.trendType)"
        :selectedPeriod="selectedPeriod"
        :periodList="[
          {
            label: $t('CALENDAR_TODAY'),
            value: {startDate: todayInIso, endDate: todayInIso},
          },
        ]"
        @update:modelValue="
          fetchCounterWidget({
            id: widget.id,
            periodStart: $event.startDate,
            periodEnd: $event.endDate,
          }),
            (selectedPeriod = $event)
        "
      />
    </div>

    <XStack class="table-container-1">
      <XMultiBox
        :boxLayout="[
          {
            name: 'table',
            width: '100%',
            contentDisplayType: 'block',
          },
        ]"
      >
        <template #tableHeaderTitle>
          <XHeading level="h4">{{ $t('CUSTOMER_MANAGER_MOST_URGENT') }}</XHeading>
        </template>
        <template #tableBody>
          <XDataTable
            v-bind="urgentCustomers"
            :columns="urgentCustomers.data?.columns && translateColumnData(urgentCustomers.data.columns)"
            :data="urgentCustomers.data?.rows ?? []"
            :autoContentHeight="true"
            :showSummary="false"
            @selectedRow="toSelectedRowDataPage($event)"
          />
        </template>
      </XMultiBox>

      <XMultiBox
        :boxLayout="[
          {
            name: 'table',
            width: '100%',
            contentDisplayType: 'block',
          },
        ]"
      >
        <template #tableHeaderTitle>
          <XHeading level="h4">{{ $t('CUSTOMER_MANAGER_NEW_CLIENTS') }}</XHeading>
        </template>
        <template #tableBody>
          <XDataTable
            v-bind="newCustomers"
            :columns="newCustomers.data?.columns && translateColumnData(newCustomers.data.columns)"
            :data="newCustomerRows"
            :autoContentHeight="true"
            :showSummary="false"
            @selectedRow="toSelectedRowDataPage($event)"
          />
        </template>
      </XMultiBox>
    </XStack>

    <div class="table-container-2">
      <div class="new-client-container" v-if="showEditForm">
        <CustomerEditForm @closeButtonClick="showEditForm = false" @saved="onCustomerSaved" />
      </div>
      <XMultiBox
        v-else
        :boxLayout="[
          {
            name: 'toolbar',
            width: '25%',
            contentDisplayType: 'block',
          },
          {
            name: 'content',
            width: '75%',
            contentDisplayType: 'block',
          },
        ]"
        containerHeight="initial"
      >
        <template #toolbarHeaderTitle>
          <div :class="{'background-opacity': showEditForm}">
            <XHeading level="h4">{{ $t('CUSTOMER_MANAGER_FILTER_CLIENTS') }}</XHeading>
          </div>
        </template>
        <template #toolbarBody>
          <div :class="{'background-opacity': showEditForm}">
            <XNavBar
              v-if="$can.read.businesscustomers && $can.read.privatecustomers"
              :active="activeFilterMenuItem"
              @itemId="itemId => (activeFilterMenuItem = itemId)"
              :navItems="[
                {id: 'private', name: $t('CUSTOMER_MANAGER_FILTER_PRIVATE')},
                {
                  id: 'business',
                  name: $t('CUSTOMER_MANAGER_FILTER_BUSINESS'),
                },
              ]"
            />
            <template v-for="(searchState, key) in customerSearchStates"
              ><template v-if="activeFilterMenuItem == key">
                <XMultiSelect
                  :key="key"
                  class="multiselect-input"
                  :placeholder="$t('CUSTOMER_MANAGER_SEARCH')"
                  :caret="false"
                  :canClear="false"
                  :showOptions="false"
                  :searchOnly="true"
                  createTags
                  :tagsBelow="searchState.searchQueries.value.length !== 0 ? true : false"
                  clearIconColor="var(--main-background)"
                  :modelValue="searchState.searchQueries.value"
                  @update:modelValue="searchState.setSearchQueries($event)" />
                <div class="advanced-filters" :key="key">
                  <XStack direction="vertical">
                    <template v-for="(field, key) in searchState.fields">
                      <XInput
                        v-if="!field.type"
                        :key="key"
                        :placeholder="field.label"
                        :modelValue="searchState.inputs.value.get(key)"
                        @update:modelValue="$event => searchState.setFilter(key, $event)"
                      />
                      <XTreeSelect
                        v-else-if="field.type == 'treeSelect'"
                        :placeholder="$t('CUSTOMER_MANAGER_FILTER_DIVISIONID')"
                        :options="field.options"
                        :modelValue="searchState.inputs.value.get(key)"
                        @update:modelValue="$event => searchState.setFilter(key, $event)"
                      />
                      <XSelect
                        v-else-if="field.type == 'select'"
                        :options="field.options"
                        :key="`${key}-select`"
                        :placeholder="field.label"
                        :clearable="true"
                        :modelValue="searchState.inputs.value.get(key)"
                        @update:modelValue="$event => searchState.setFilter(key, $event)"
                      />
                    </template>
                  </XStack></div></template
            ></template>
          </div>
        </template>

        <template #contentHeaderTitle>
          <XHeading level="h4">
            {{ $t('CUSTOMER_MANAGER_CLIENT_OVERVIEW') }}
          </XHeading>
        </template>
        <template #contentHeaderButtons>
          <div class="clientoverview-buttons" style="padding-right: var(--spacing-md)">
            <XButton
              variant="outlined"
              icon="plus-circle"
              @click="showEditForm = true"
              v-if="$can.create.customermanager"
            >
              {{ $t('CUSTOMER_MANAGER_ADD_CLIENT') }}
            </XButton>
          </div>
        </template>
        <template #contentBody>
          <template v-for="(searchState, key) in customerSearchStates">
            <XDataTable
              :key="key"
              v-if="activeFilterMenuItem == key"
              v-bind="searchState.tableProps.value"
              :filterColumns="false"
              :columns="translateColumnData(searchState.columns.value)"
              :data="searchState.rows.value"
              :showSummary="false"
              @selectedRow="toSelectedRowDataPage($event)"
              enableColumnVisibilityToggle
            />
          </template>
        </template>
      </XMultiBox>
    </div>
  </XWrapper>
</template>

<script src="./customerOverview.ts" />

<style lang="scss" scoped>
::v-deep(.box-body) {
  border-bottom: 1px solid var(--container-border, #e8e8e8);
  div {
    .main-title {
      padding-left: 0.25rem;

      h4.heading {
        font-size: 14px;
      }
    }
  }
}

::v-deep(.main-box .slot-content) {
  margin-top: -1rem;
  padding-right: 1.5rem;
  padding-left: 1.25rem;
  padding-top: 0;
}

::v-deep(.multibox-container .multibox-part .multibox-header) {
  padding: var(--spacing-xl);
  align-items: center;
  .title {
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.loading {
  opacity: 0.5;
}

:deep .multibox-body .nav {
  .menu-item {
    flex: 1;
    max-width: none;
    > * {
      justify-content: center;
    }
    padding: 1rem 0;
  }
}

.trend-widgets {
  width: 100%;
  display: flex;
  column-gap: 0.7rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;

  > div {
    flex: 1;
  }
}

.table-container-1 {
  :deep .multibox-body.tableBody {
    margin: 0rem 0.7rem 0rem 0.7rem;
  }
}

.table-container-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;

  :deep .multibox-body.contentBody {
    height: 100%;
    margin: 0rem 0.7rem 0rem 0.7rem;
  }
}

.dropdown-container {
  min-height: 38rem;
}

.advanced-filters {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.clientoverview-buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  ::v-deep(.XMenuBox) {
    margin-top: 1rem;
    margin-right: 0.7rem;

    .togglemenu {
      width: 14rem;
    }
  }
}

.new-client-container {
  min-width: 30rem;
  max-width: 100%;
  display: flex;
  height: 100%;
  border-right: 1px solid var(--container-border, #e8e8e8);

  .new-client-inputs {
    margin: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 15rem;
  }
}

.background-opacity {
  background-color: var(--main-background, #ffffff);
  opacity: 0.3;
  pointer-events: none;
}

.multiselect-input {
  padding: var(--spacing-lg);
  width: initial;
  border-bottom: 1px solid var(--container-border, #e8e8e8);
  border-top: 1px solid var(--container-border, #e8e8e8);
}

:deep .multiselect-tags {
  padding-left: 0.1rem;
}

.close-new-client-box {
  width: 3.7rem;
  height: 3.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--container-border, #e8e8e8);
  cursor: pointer;
}
</style>

import {h, createApp, version} from 'vue';

import App from './AppCustomerManager.vue';
import router from './router';
import singleSpaVue from 'single-spa-vue';
import {createPinia} from 'pinia';
import {plugin as dnx} from '@dnx/core';

export const lifecycle = singleSpaVue({
  createApp,

  // promisifying seems to delay execution until post router/auth init ensuring working redirect away from page on reload
  appOptions: Promise.resolve({
    render: () => h(App),
    el: '#application',
  }),
  handleInstance: app => {
    app.use(router).use(createPinia()).use(Window.runtime.store).use(dnx);
  },
});

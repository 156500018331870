var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createResetMutation } from '@dnx/core';
import customerManager from '../../app/api-generated/customerManager';
import customers from '../../app/api-generated/customers';
import customerManagerDetails from '../../app/api-generated/customerManagerDetails';
import contacts from '../../app/api-generated/contacts';
import { T } from '@dnx/core';
import checklists from '../../app/api-generated/checklists';
import { CustomerEventSource } from '../../app/api-generated/enums';
const GET_INITIAL_STATE = () => ({
    allCounterWidgets: [],
    counterWidgetKeys: {},
    tableUrgentData: [],
    tableNewCustomersData: [],
    TableCustomerOverviewData: [],
    customer: undefined,
    editingCustomer: undefined,
    privateCustomerSufficientPropertySets: [],
    businessCustomerSufficientPropertySets: [],
    simpleStatistics: [],
    generalInformation: [],
    timelineData: [],
    lastPurchaseData: [],
    checklistData: [],
    miscellaneousData: [],
    isLoadingCustomers: false,
    sections: undefined,
    selectedMenuItem: 1,
    contacts: [],
    timelineEventTypes: {},
    legalForms: [],
});
let customerSearchAbortController;
export default {
    namespaced: true,
    state: GET_INITIAL_STATE(),
    getters: {
        getAllCounterWidgets: (state) => state.allCounterWidgets,
        getCounterWidgetKeys: (state) => state.counterWidgetKeys,
        getTableUrgentData: (state) => state.tableUrgentData,
        getIsLoadingCustomers: (state) => state.isLoadingCustomers,
        getTableNewCustomersData: (state) => state.tableNewCustomersData,
        getTableCustomerOverviewData: (state) => state.TableCustomerOverviewData,
        getCustomer: (state) => state.customer,
        getEditingCustomer: (state) => state.editingCustomer,
        getSimpleStatistics: (state) => state.simpleStatistics,
        getGeneralInformation: (state) => state.generalInformation,
        getTimelineData: (state) => state.timelineData,
        getLastPurchaseData: (state) => state.lastPurchaseData,
        getChecklistData: (state) => state.checklistData,
        getMiscellaneousData: (state) => state.miscellaneousData,
        getSections: (state) => state.sections,
        getSelectedMenuItem: (state) => state.selectedMenuItem,
        getPrivateCustomerSufficientPropertySets: (state) => state.privateCustomerSufficientPropertySets,
        getBusinessCustomerSufficientPropertySets: (state) => state.businessCustomerSufficientPropertySets,
        getLegalForms: (state) => state.legalForms,
        getContacts: (state) => state.contacts,
        getTimelineEventTypes: (state) => state.timeLineEventTypes,
        timelineEventTypesToTreeSelect: (state) => {
            return (state.timeLineEventTypes &&
                Object.entries(state.timeLineEventTypes).map(([type, subtype]) => ({
                    id: type,
                    label: T(type),
                    children: subtype.map(child => ({
                        id: child.id,
                        label: T(child.translationKey),
                    })),
                })));
        },
    },
    actions: {
        //  Overview page
        fetchAllCounterWidgets(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield customerManager.getCounterWidgetDefinitions();
                if (!response.success) {
                    commit('setAllCounterWidgets', []);
                    return;
                }
                commit('setAllCounterWidgets', response.value);
            });
        },
        fetchCounterWidget(_a, payload_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, payload) {
                const response = yield customerManager.getCounterWidgetData(payload.id, payload.periodStart, payload.periodEnd);
                if (!response.success)
                    return;
                commit('setCounterWidgetKeys', {
                    id: payload.id,
                    response: response.value,
                });
            });
        },
        fetchTableUrgentData(_a, urgentData_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, urgentData) {
                let sort = undefined;
                if (urgentData.sortDirection === 'ascend')
                    sort = '+' + urgentData.sortColumn;
                if (urgentData.sortDirection === 'ascend')
                    sort = '-' + urgentData.sortColumn;
                const page = urgentData.skip / urgentData.take + 1;
                const response = yield customerManager.getTableUrgentData(undefined, sort, page, urgentData.take);
                commit('setTableUrgentData', response.value);
            });
        },
        fetchTableNewCustomersData(_a, customersData_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customersData) {
                let sort = undefined;
                if (customersData.sortDirection === 'ascend')
                    sort = '+' + customersData.sortColumn;
                if (customersData.sortDirection === 'ascend')
                    sort = '-' + customersData.sortColumn;
                const page = customersData.skip / customersData.take + 1;
                const response = yield customerManager.getTableNewCustomersData(undefined, sort, page, customersData.take);
                commit('setTableNewCustomersData', response.value);
            });
        },
        fetchTableCustomerOverviewData(_a, advancedFilters_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, advancedFilters) {
                // if (advancedFilters.sortDirection == 'ascend') advancedFilters.sortDirection = 'asc';
                // if (advancedFilters.sortDirection == 'descend') advancedFilters.sortDirection = 'desc';
                // if (advancedFilters.sortDirection == false) {
                //   advancedFilters.sortDirection = undefined;
                //   advancedFilters.sortColumn = '';
                // }
                // let sort = undefined;
                // if ( 'sortDirection' in advancedFilters && 'sortColumn' in advancedFilters ) {
                //   if ( advancedFilters.sortDirection === 'ascend' ) sort = '+' + advancedFilters.sortColumn
                //   if ( advancedFilters.sortDirection === 'ascend' ) sort = '-' + advancedFilters.sortColumn
                // }
                // console.log(advancedFilters)
                // const page = (advancedFilters.skip / advancedFilters.take) + 1;
                commit('setIsLoadingCustomers', true);
                customerSearchAbortController === null || customerSearchAbortController === void 0 ? void 0 : customerSearchAbortController.abort();
                customerSearchAbortController = new AbortController();
                try {
                    if (advancedFilters.type == 'business') {
                        const response = yield customerManager.searchCompanies(advancedFilters, undefined, undefined, undefined, {
                            signal: customerSearchAbortController.signal,
                        });
                        commit('setTableCustomerOverviewData', response.value);
                    }
                    else {
                        const response = yield customerManager.searchCustomers(advancedFilters, undefined, undefined, undefined, {
                            signal: customerSearchAbortController.signal,
                        });
                        commit('setTableCustomerOverviewData', response.value);
                    }
                }
                catch (_b) { }
                commit('setIsLoadingCustomers', false);
            });
        },
        //  Customer map page
        fetchSections(_a, customerId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customerId) {
                const response = yield customerManagerDetails.getSections(customerId);
                if (response.success)
                    commit('setSections', response.value);
                return response.success;
            });
        },
        fetchSimpleStatistics(_a, customerId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit, state }, customerId) {
                const response = yield customerManagerDetails.getScores(customerId);
                if (response.success)
                    commit('setSimpleStatistics', response.value);
                // @ts-ignore
                else
                    commit('setSections', Object.assign(Object.assign({}, state.sections), { showScores: false }));
                return response.success;
            });
        },
        fetchGeneralInformation(_a, customerId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customerId) {
                const response = yield customerManagerDetails.getProfileInformation(customerId);
                if (response.success)
                    commit('setGeneralInformation', response.value);
                return response.success;
            });
        },
        fetchTimeLineData(_a, customerId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customerId) {
                const response = yield customerManagerDetails.getTimelineData(customerId);
                if (!response.success && response.value)
                    return;
                //These values won't get send back, and the frontend requires some kind of uniqueness
                response.value.forEach(timelineData => {
                    if (timelineData.id == '00000000-0000-0000-0000-000000000000')
                        timelineData.id = `00000000-0000-0000-0000-${1e12 + Math.random() * 1e11}`;
                    if (timelineData.source == CustomerEventSource.System) {
                        timelineData.displayName = T(timelineData.displayName);
                        timelineData.description = T(timelineData.description);
                    }
                });
                commit('setTimelineData', response.value);
            });
        },
        fetchLegalForms(_a, customerId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customerId) {
                const response = yield customerManager.legalForms();
                if (!response.success)
                    return;
                commit('setLegalForms', response.value);
            });
        },
        fetchLastPurchaseData(_a, customerId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customerId) {
                const response = yield customerManagerDetails.getLastPurchaseData(customerId);
                commit('setLastPurchaseData', response.value);
            });
        },
        fetchChecklistData(_a, customerId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customerId) {
                const response = yield checklists.getCustomerChecklistItemStates('NEW_CUSTOMER', customerId);
                commit('setChecklistData', response.value.itemStates);
            });
        },
        fetchMiscellaneousData(_a, customerId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customerId) {
                const response = yield customerManagerDetails.getMiscellaneous(customerId);
                if (!response.success || !response.value)
                    return;
                commit('setMiscellaneousData', JSON.parse(response.value));
            });
        },
        //Customer edit form
        fetchEditableCustomer(_a, id_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit, state }, id) {
                if (!state.editingCustomer || state.editingCustomer.id !== id) {
                    const response = yield customers.get(id);
                    commit('setEditingCustomer', response.value);
                }
            });
        },
        createPrivateCustomer(_a, customer_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customer) {
                var _b, _c;
                const response = yield customers.postPerson(customer);
                if (!response.success) {
                    throw new Error((_c = (_b = response.value) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : response.message);
                }
            });
        },
        createBusinessCustomer(_a, customer_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, customer) {
                var _b, _c;
                const response = yield customers.postCompany(customer);
                if (!response.success) {
                    throw new Error((_c = (_b = response.value) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : response.message);
                }
            });
        },
        updatePrivateCustomer(_a, _b) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, { id, customer }) {
                var _c, _d;
                const response = yield customers.putPerson(id, customer);
                if (!response.success) {
                    throw new Error((_d = (_c = response.value) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : response.message);
                }
            });
        },
        updateBusinessCustomer(_a, _b) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, { id, customer }) {
                var _c, _d;
                const response = yield customers.putCompany(id, customer);
                if (!response.success) {
                    throw new Error((_d = (_c = response.value) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : response.message);
                }
            });
        },
        fetchCustomerSufficientPropertySets(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const privateResponse = yield customers.getSufficientPropertySetsForPrivateCustomer();
                commit('setPrivateCustomerSufficientPropertySets', privateResponse.value);
                const businessResponse = yield customers.getSufficientPropertySetsForBusinessCustomer();
                commit('setBusinessCustomerSufficientPropertySets', businessResponse.value);
            });
        },
        fetchContacts(_a, id_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, id) {
                const response = yield contacts.list(id);
                commit('setContacts', response.value);
            });
        },
        fetchTimelineEventTypes(_a, id_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, id) {
                const response = yield customerManagerDetails.getTimelineEventTypes(id);
                commit('setTimelineEventTypes', response.value);
            });
        },
    },
    mutations: {
        setAllCounterWidgets: (state, data) => (state.allCounterWidgets = data),
        setCounterWidgetKeys: (state, data) => (state.counterWidgetKeys[data.id] = data.response),
        setTableUrgentData: (state, data) => (state.tableUrgentData = data),
        setIsLoadingCustomers: (state, data) => (state.isLoadingCustomers = data),
        setTableNewCustomersData: (state, data) => (state.tableNewCustomersData = data),
        setTableCustomerOverviewData: (state, data) => (state.TableCustomerOverviewData = data),
        setCustomer: (state, data) => (state.customer = data),
        // Customer map page
        setSections: (state, data) => (state.sections = data),
        setSimpleStatistics: (state, data) => (state.simpleStatistics = data),
        setGeneralInformation: (state, data) => (state.generalInformation = data),
        setTimelineData: (state, data) => (state.timelineData = data),
        setLastPurchaseData: (state, data) => (state.lastPurchaseData = data),
        setChecklistData: (state, data) => (state.checklistData = data),
        setMiscellaneousData: (state, data) => (state.miscellaneousData = data),
        setSelectedMenuItem: (state, id) => (state.selectedMenuItem = id),
        setLegalForms: (state, data) => (state.legalForms = data),
        setEditingCustomer: (state, data) => (state.editingCustomer = data),
        setPrivateCustomerSufficientPropertySets: (state, data) => (state.privateCustomerSufficientPropertySets = data),
        setBusinessCustomerSufficientPropertySets: (state, data) => (state.businessCustomerSufficientPropertySets = data),
        setContacts: (state, data) => (state.contacts = data),
        setTimelineEventTypes: (state, data) => (state.timeLineEventTypes = data),
        reset: createResetMutation(GET_INITIAL_STATE),
    },
};

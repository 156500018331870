import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-6fb458c2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "editor-values" };
const _hoisted_2 = { class: "controls" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XInput = _resolveComponent("XInput");
    const _component_XIcon = _resolveComponent("XIcon");
    const _component_XButton = _resolveComponent("XButton");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_XInput, {
            modelValue: _ctx.misc.displayName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.misc.displayName) = $event)),
            placeholder: _ctx.$t('CUSTOMER_MANAGER_EVENT_MISCELLANEOUS_DISPLAY')
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_XInput, {
            modelValue: _ctx.misc.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.misc.value) = $event)),
            placeholder: _ctx.$t('CUSTOMER_MANAGER_EVENT_MISCELLANEOUS_VALUE')
        }, null, 8, ["modelValue", "placeholder"]),
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_XButton, {
                class: "delete-btn",
                "component-style": "white",
                size: "small",
                onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('delete')))
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_XIcon, {
                        name: "delete",
                        size: "small"
                    })
                ]),
                _: 1
            })
        ])
    ]));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class Checklists {
    constructor() {
        this.paths = Object.freeze({
            getChecklist: (type) => `api/checklists/customers/${encodeURIComponent(type)}`,
            getCustomerChecklistItemStates: (type, customerid) => `api/checklists/customers/${encodeURIComponent(type)}/states/${encodeURIComponent(customerid)}`,
            saveCustomerChecklistItemStates: (customerid) => `api/checklists/customers/${encodeURIComponent(customerid)}`,
        });
    }
    /**
     * Checklists.GetChecklist "GET api/checklists/customers/{type}"
     * @param {string} type
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ChecklistModel>>}
     */
    getChecklist(type, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/checklists/customers/${type === undefined || type === null ? "" : encodeURIComponent(type)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Checklists.GetCustomerChecklistItemStates "GET api/checklists/customers/{type}/states/{customerId}"
     * @param {string} type
     * @param {Guid} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ChecklistItemStateModel>>}
     */
    getCustomerChecklistItemStates(type, customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/checklists/customers/${type === undefined || type === null ? "" : encodeURIComponent(type)}/states/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Checklists.SaveCustomerChecklistItemStates "PUT api/checklists/customers/{customerId}"
     * @param {ChecklistItemStatesModel} checklistitemstatesmodel
     * @param {Guid} customerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    saveCustomerChecklistItemStates(checklistitemstatesmodel, customerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/checklists/customers/${customerid === undefined || customerid === null ? "" : encodeURIComponent(customerid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, checklistitemstatesmodel);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const checklists = new Checklists();
export { checklists as default, Checklists };

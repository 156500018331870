import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-12113651"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "header" };
const _hoisted_2 = { class: "timeline-top-buttons" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "vertical-timeline-details" };
const _hoisted_6 = { style: { "color": "var(--text-primary-500, #868686)" } };
const _hoisted_7 = { style: { "position": "relative", "width": "100%" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XParagraph = _resolveComponent("XParagraph");
    const _component_XDateRangePicker = _resolveComponent("XDateRangePicker");
    const _component_XSelect = _resolveComponent("XSelect");
    const _component_XTreeSelect = _resolveComponent("XTreeSelect");
    const _component_XGrid = _resolveComponent("XGrid");
    const _component_XIcon = _resolveComponent("XIcon");
    const _component_XButton = _resolveComponent("XButton");
    const _component_XStack = _resolveComponent("XStack");
    const _component_XTimelineBox = _resolveComponent("XTimelineBox");
    const _component_XNoData = _resolveComponent("XNoData");
    const _component_CustomerTimelineView = _resolveComponent("CustomerTimelineView");
    const _component_CustomerAddEvent = _resolveComponent("CustomerAddEvent");
    const _component_XMultiBox = _resolveComponent("XMultiBox");
    return (_ctx.selectedMenuItem === 1)
        ? (_openBlock(), _createBlock(_component_XMultiBox, {
            key: 0,
            class: "timeline-container",
            boxLayout: [
                {
                    name: 'toolbar',
                },
            ],
            containerHeight: "initial"
        }, {
            toolbarHeaderTitle: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_XParagraph, {
                        componentStyle: "large",
                        color: "var(--text-secondary, #083856)"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('CUSTOMER_MANAGER_TIMELINE')), 1)
                        ]),
                        _: 1
                    })
                ]),
                _createVNode(_component_XStack, {
                    alignment: "between",
                    style: { "width": "100%", "white-space": "nowrap" }
                }, {
                    default: _withCtx(() => {
                        var _a;
                        return [
                            _createVNode(_component_XGrid, {
                                class: "filters",
                                columns: "4"
                            }, {
                                default: _withCtx(() => {
                                    var _a, _b;
                                    return [
                                        _createVNode(_component_XDateRangePicker, {
                                            label: _ctx.$t('CM_TIMELINE_FILTER_DATE'),
                                            opens: "right",
                                            setStartDate: (_a = _ctx.eventFilters.eventDate) === null || _a === void 0 ? void 0 : _a.startDate,
                                            setEndDate: (_b = _ctx.eventFilters.eventDate) === null || _b === void 0 ? void 0 : _b.endDate,
                                            onUpdateValues: _cache[0] || (_cache[0] = ($event) => (_ctx.eventFilters.eventDate = $event))
                                        }, null, 8, ["label", "setStartDate", "setEndDate"]),
                                        ($props.showContactInformation)
                                            ? (_openBlock(), _createBlock(_component_XSelect, {
                                                key: 0,
                                                clearable: true,
                                                placeholder: _ctx.$t('CM_TIMELINE_FILTER_CONTACTS'),
                                                options: $options.contactPersonOptions,
                                                modelValue: _ctx.eventFilters.contactPerson,
                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_ctx.eventFilters.contactPerson = $event))
                                            }, null, 8, ["placeholder", "options", "modelValue"]))
                                            : _createCommentVNode("", true),
                                        _createVNode(_component_XTreeSelect, {
                                            placeholder: _ctx.$t('CM_TIMELINE_FILTER_EVENT_TYPE'),
                                            options: _ctx.timelineEventTypeList,
                                            modelValue: _ctx.eventFilters.eventType,
                                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (_ctx.eventFilters.eventType = $event))
                                        }, null, 8, ["placeholder", "options", "modelValue"]),
                                        _createVNode(_component_XSelect, {
                                            clearable: true,
                                            placeholder: _ctx.$t('CM_TIMELINE_FILTER_EVENT_SOURCE'),
                                            options: [
                                                {
                                                    value: 'custom',
                                                    label: _ctx.$t('CM_TIMELINE_FILTER_EVENT_SOURCE_MANUAL'),
                                                },
                                                {
                                                    value: 'system',
                                                    label: _ctx.$t('CM_TIMELINE_FILTER_EVENT_SOURCE_SYSTEM'),
                                                },
                                            ],
                                            modelValue: _ctx.eventFilters.eventSource,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => (_ctx.eventFilters.eventSource = $event))
                                        }, null, 8, ["placeholder", "options", "modelValue"])
                                    ];
                                }),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_2, [
                                (_ctx.addNewEventBox)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        (_ctx.$can.write.customermanager)
                                            ? (_openBlock(), _createBlock(_component_XButton, {
                                                key: 0,
                                                "component-style": "white",
                                                onClick: _cache[4] || (_cache[4] = () => { var _a; return (_a = $setup.eventEditorInstance) === null || _a === void 0 ? void 0 : _a.close(); }),
                                                style: { "margin": "0.7rem", "margin-right": "0.35rem" }
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_XIcon, {
                                                        name: "clear",
                                                        title: "clear"
                                                    }),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('CANCEL')), 1)
                                                ]),
                                                _: 1
                                            }))
                                            : _createCommentVNode("", true),
                                        _createVNode(_component_XButton, {
                                            "component-style": "secondary-success",
                                            style: { "margin": "0.7rem", "margin-left": "0.35rem" },
                                            onClick: _cache[5] || (_cache[5] = () => { var _a; return (_a = $setup.eventEditorInstance) === null || _a === void 0 ? void 0 : _a.save(); }),
                                            disabled: !((_a = $setup.eventEditorInstance) === null || _a === void 0 ? void 0 : _a.canSave)
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_XIcon, {
                                                    name: "check-circle",
                                                    title: "check-circle"
                                                }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$t('SAVE')), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["disabled"])
                                    ]))
                                    : (_ctx.$can.write.customermanager)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                            _createVNode(_component_XButton, {
                                                "component-style": "white",
                                                onClick: $options.createNewEvent,
                                                style: { "margin": "0.7rem" }
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_XIcon, {
                                                        name: "plus-circle",
                                                        size: "custom"
                                                    }),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('CUSTOMER_MANAGER_ADD_EVENT')), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["onClick"])
                                        ]))
                                        : _createCommentVNode("", true)
                            ])
                        ];
                    }),
                    _: 1
                })
            ]),
            toolbarBody: _withCtx(() => [
                _createVNode(_component_XStack, {
                    gap: "0",
                    style: { "width": "100%" }
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", {
                            class: _normalizeClass(["vertical-timeline", { 'background-opacity': _ctx.addNewEventBox }])
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.translationTimeLineData($options.filteredTimelineEvents), (item, index) => {
                                return (_openBlock(), _createBlock(_component_XTimelineBox, {
                                    key: item.id,
                                    icon: item.iconName,
                                    iconColor: $options.resolveColor(item.color),
                                    title: item.displayName,
                                    createdBy: item.source != 'system'
                                        ? _ctx.$t('CUSTOMER_MANAGER_CREATED_BY', {
                                            name: item.createdBy,
                                        })
                                        : undefined,
                                    date: _ctx.$format(item.date, item.date.includes('00:00:00Z') ? 'date' : 'datetimeWithoutSeconds'),
                                    description: $options.getTimelineEventTypeName(item.eventTypeId),
                                    active: item.id === _ctx.activeNodeId,
                                    editable: $options.isEditable(item),
                                    onEdit: ($event) => ($options.editEvent(item)),
                                    onToggle: ($event) => ($options.setNodeActive(item)),
                                    id: item.id
                                }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_5, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getTimelineDetails(item), (detail, index) => {
                                                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                                    _createElementVNode("span", _hoisted_6, _toDisplayString(detail.displayName), 1),
                                                    _createElementVNode("span", null, _toDisplayString(detail.value), 1)
                                                ], 64));
                                            }), 128))
                                        ])
                                    ]),
                                    _: 2
                                }, 1032, ["icon", "iconColor", "title", "createdBy", "date", "description", "active", "editable", "onEdit", "onToggle", "id"]));
                            }), 128)),
                            ($options.noResults && !_ctx.loadingEvents)
                                ? (_openBlock(), _createBlock(_component_XNoData, {
                                    key: 0,
                                    noResults: true
                                }))
                                : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", _hoisted_7, [
                            (!_ctx.addNewEventBox)
                                ? (_openBlock(), _createBlock(_component_CustomerTimelineView, {
                                    key: 0,
                                    nodes: $options.filteredTimelineEvents,
                                    ref: "timelineView",
                                    "active-node-id": _ctx.activeNodeId,
                                    onClickNode: _cache[6] || (_cache[6] =
                                        node => {
                                            $options.setNodeActive(node);
                                            $options.flyToNode(node);
                                        })
                                }, null, 8, ["nodes", "active-node-id"]))
                                : _createCommentVNode("", true),
                            (_ctx.addNewEventBox && _ctx.$can.write.customermanager)
                                ? (_openBlock(), _createBlock(_component_CustomerAddEvent, {
                                    key: 1,
                                    ref: "eventEditorInstance",
                                    style: { "width": "100%" },
                                    showContactInformation: $props.showContactInformation,
                                    modelValue: _ctx.newEvent,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((_ctx.newEvent) = $event)),
                                    language: _ctx.preferredLanguage,
                                    canSave: _ctx.state,
                                    onClose: _cache[8] || (_cache[8] = ($event) => (_ctx.addNewEventBox = false)),
                                    onSave: _cache[9] || (_cache[9] = () => _ctx.fetchTimelineData(_ctx.customerId))
                                }, null, 8, ["showContactInformation", "modelValue", "language", "canSave"]))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class CustomerManager {
    constructor() {
        this.paths = Object.freeze({
            getCounterWidgetDefinitions: () => `api/CustomerManager/CounterWidgets`,
            getCounterWidgetData: (id, periodstart, periodend) => {
                const __query = new QueryStringBuilder().add("periodStart", periodstart).add("periodEnd", periodend);
                return `api/CustomerManager/CounterWidget/${encodeURIComponent(id)}${__query.toString()}`;
            },
            getTableUrgentData: () => {
                return `api/CustomerManager/TableUrgent`;
            },
            getTableNewCustomersData: () => {
                return `api/CustomerManager/TableNewCustomers`;
            },
            searchCustomers: () => {
                return `api/CustomerManager/TableCustomerOverview/customers/search`;
            },
            searchCompanies: () => {
                return `api/CustomerManager/TableCustomerOverview/companies/search`;
            },
            health: () => `api/CustomerManager/health`,
            legalForms: () => `api/CustomerManager/legalforms`,
        });
    }
    /**
     * CustomerManager.GetCounterWidgetDefinitions "GET api/CustomerManager/CounterWidgets"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerCounterWidgetDefinitionResponseModel[]>>}
     */
    getCounterWidgetDefinitions(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/CounterWidgets`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManager.GetCounterWidgetData "GET api/CustomerManager/CounterWidget/{id}"
     * @param {Guid} id
     * @param {DateString} periodstart
     * @param {DateString} periodend
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerCounterWidgetDataResponseModel>>}
     */
    getCounterWidgetData(id, periodstart, periodend, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("periodStart", periodstart).add("periodEnd", periodend);
            let path = `api/CustomerManager/CounterWidget/${id === undefined || id === null ? "" : encodeURIComponent(id)}${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManager.GetTableUrgentData "GET api/CustomerManager/TableUrgent"
     * @param {string} filter
     * @param {string} sort
     * @param {PaginationParameters} paginationparameters
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerTableDataResponseModel>>}
     */
    getTableUrgentData(filter, sort, pagenumber, pagesize, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("filter", filter).add("sort", sort).add("PageNumber", pagenumber).add("PageSize", pagesize);
            let path = `api/CustomerManager/TableUrgent${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManager.GetTableNewCustomersData "GET api/CustomerManager/TableNewCustomers"
     * @param {string} filter
     * @param {string} sort
     * @param {PaginationParameters} paginationparameters
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerTableDataResponseModel>>}
     */
    getTableNewCustomersData(filter, sort, pagenumber, pagesize, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("filter", filter).add("sort", sort).add("PageNumber", pagenumber).add("PageSize", pagesize);
            let path = `api/CustomerManager/TableNewCustomers${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManager.SearchCustomers "POST api/CustomerManager/TableCustomerOverview/customers/search"
     * @param {CustomerOverviewSearchRequestModel} requestmodel
     * @param {string} sort
     * @param {PaginationParameters} paginationparameters
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerTableDataResponseModel>>}
     */
    searchCustomers(requestmodel, sort, pagenumber, pagesize, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("sort", sort).add("PageNumber", pagenumber).add("PageSize", pagesize);
            let path = `api/CustomerManager/TableCustomerOverview/customers/search${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, requestmodel);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManager.SearchCompanies "POST api/CustomerManager/TableCustomerOverview/companies/search"
     * @param {CustomerOverviewSearchRequestModel} requestmodel
     * @param {string} sort
     * @param {PaginationParameters} paginationparameters
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomerTableDataResponseModel>>}
     */
    searchCompanies(requestmodel, sort, pagenumber, pagesize, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("sort", sort).add("PageNumber", pagenumber).add("PageSize", pagesize);
            let path = `api/CustomerManager/TableCustomerOverview/companies/search${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, requestmodel);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManager.Health "GET api/CustomerManager/health"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<any>>}
     */
    health(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/health`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CustomerManager.LegalForms "GET api/CustomerManager/legalforms"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<string[]>>}
     */
    legalForms(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/CustomerManager/legalforms`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const customerManager = new CustomerManager();
export { customerManager as default, CustomerManager };

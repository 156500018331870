<template>
  <div class="small-widgets" v-if="sections.showScores">
    <XBox v-for="widget in simpleStatistics" :key="widget.id">
      <div class="small-widget-icon" :style="{'background-color': getBackgroundColor(widget.color)}">
        <XIcon :name="widget.iconName" :color="widget.color" />
      </div>
      <div class="small-widget-text">
        <XParagraph componentStyle="title" color="var(--text-secondary, #083856)">
          {{ getStatisticDisplayValue(widget) }}
        </XParagraph>
        <XParagraph componentStyle="medium" color="var(--text-primary-500, #A5A5A5)">
          {{ translateWidgetName(widget.description) }}
        </XParagraph>
      </div>
    </XBox>
  </div>

  <div>
    <div v-if="showEditForm && $can.write.customermanager">
      <CustomerEditForm
        :customerId="this.$route.params.id"
        @closeButtonClick="showEditForm = false"
        @saved="onCustomerSaved"
      />
    </div>
    <XBox class="general-information" v-else>
      <XLoadingSpinner :loading="true" v-if="generalInformation.length === 0" />
      <div class="top-info" v-else>
        <div class="avatar">
          <div class="ranking-symbol">
            <XStack
            class="customer-icon"
            :style="{
              'border-color': getRankingColor(generalInformation.ranking),
            }"
            >
              <XIcon name="person" size="large" :color="getRankingColor(generalInformation.ranking)" />
            </XStack>
            <div
              class="ranking-symbol"
              :style="{
                background: getRankingColor(generalInformation.ranking),
              }"
            >
              <XIcon name="star" color="white-blue" />
            </div>
          </div>

          <div class="information-left">
            <XParagraph componentStyle="title">
              {{ generalInformation.name }}
            </XParagraph>
            <XParagraph componentStyle="medium" color="var(--text-primary-500, #868686)">
              {{ generalInformation.externalIdentifier }}
            </XParagraph>
            <XParagraph componentStyle="medium" color="var(--text-primary-500, #868686)">
              {{ generalInformation.email }}
            </XParagraph>
          </div>
        </div>
        <div class="information-right" v-for="(section, index) in miscellaneousSections" :key="index">
          <div v-for="(item, index) in translateSection(section)" :key="index" class="profile-details">
            <XParagraph
              componentStyle="medium"
              :color="item.isMasked ? 'var(--text-primary-400, #A5A5A5)' : 'var(--text-primary)'"
            >
              {{ item.displayName }}
            </XParagraph>
            <XParagraph
              componentStyle="medium"
              :color="item.isMasked ? 'var(--text-primary-400, #A5A5A5)' : 'var(--text-primary)'"
            >
              <!-- todo: checken wat hier de color namen van gaan worden afhankelijk van de DNDS themes. -->
              {{ item.isMasked ? item.value.replace(/./g, '*') : item.value }}
            </XParagraph>
          </div>
        </div>
        <div class="update-button-container">
          <XButton variant="outlined" @click="showEditForm = true" v-if="$can.write.customermanager">
            <XIcon name="plus-circle" />
            {{ $t('CUSTOMER_MANAGER_EDIT') }}
          </XButton>
        </div>
      </div>
    </XBox>
    <XBox class="general-information-below" boxBelow>
      <XNavBar
        :inBoxBelow="true"
        @itemId="setSelectedMenuItem($event)"
        :active="selectedMenuItem"
        :groupItems="menuItems"
      />
    </XBox>
  </div>
</template>

<script>
import CustomerEditForm from '../../components/CustomerEditForm.vue';
import {convertToHsl, T} from '@dnx/core';
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'CustomerProfile',
  components: {
    CustomerEditForm,
  },
  data() {
    return {
      showEditForm: false,
    };
  },
  computed: {
    ...mapGetters({
      simpleStatistics: 'customerManager/getSimpleStatistics',
      customer: 'customerManager/getCustomer',
      sections: 'customerManager/getSections',
      generalInformation: 'customerManager/getGeneralInformation',
      selectedMenuItem: 'customerManager/getSelectedMenuItem',
      preferredLanguage: 'locale/preferredLanguage',
    }),

    menuItems() {
      const menuItems = [
        {name: T('CUSTOMER_MANAGER_CUSTOMER_MAP'), id: 1},
        {
          name: T('CUSTOMER_MANAGER_CUSTOMER_CONSENTS'),
          description: T('CUSTOMER_MANAGER_COMING_SOON'),
          id: 2,
          disabled: true,
        },
        {
          name: T('CUSTOMER_MANAGER_CUSTOMER_INSIGHTS'),
          description: T('CUSTOMER_MANAGER_COMING_SOON'),
          id: 3,
          disabled: true,
        },
      ];

      if (this.sections.showChecklist || this.sections.showMiscellaneous) {
        menuItems.push({
          name: T('CUSTOMER_MANAGER_CUSTOMER_MISC'),
          id: 4,
          disabled: false,
        });
      }

      return menuItems;
    },

    miscellaneousSections() {
      const sections = [];
      const miscellaneous = this.generalInformation.miscellaneous || [];

      //Create sections consisting of max 5 items each
      //[1,2,3,4,5,6,7,8] > [[1,2,3,4,5], [6,7,8]]
      for (let idx = 0; idx < miscellaneous.length; idx += 5) {
        sections.push(miscellaneous.slice(idx, idx + 5));
      }

      return sections;
    },
  },
  async created() {
    await this.fetchSimpleStatistics(this.customer ? this.customer.id : this.$route.params.id);
    await this.fetchGeneralInformation(this.customer ? this.customer.id : this.$route.params.id);
    if (!this.simpleStatistics || !this.generalInformation) {
      this.triggerAlertMessage({
        type: 'error',
        message: T('ERROR_CUSTOMER_NOT_FOUND'),
      });
      try {
        if (window.history.state && window.history.state.back) {
          this.$router.back();
        } else {
          this.$router.push({path: '/customer-manager'});
        }
      } catch {
        this.$router.push({path: '/customer-manager'});
      }
    }
  },
  unmounted() {
    this.setGeneralInformation([]);
  },
  methods: {
    ...mapActions({
      fetchSimpleStatistics: 'customerManager/fetchSimpleStatistics',
      fetchGeneralInformation: 'customerManager/fetchGeneralInformation',
      triggerAlertMessage: 'notifications/triggerAlertMessage',
    }),
    ...mapMutations({
      setSelectedMenuItem: 'customerManager/setSelectedMenuItem',
      setGeneralInformation: 'customerManager/setGeneralInformation',
    }),
    getBackgroundColor(colorVar) {
      const computedStyling = getComputedStyle(document.documentElement);
      const hexColor = computedStyling.getPropertyValue(colorVar);
      // Brightness 90 is closest to the design
      return convertToHsl(hexColor || '#ffffff', 90);
    },
    getRankingColor(ranking) {
      // This are the predefined ranking types, this could change in the future
      const colors = {
        gold: '#FFCC00',
        silver: '#C0C0C0',
        bronze: '#CD7F32',
      };

      const key = ranking ? ranking.toLowerCase() : undefined;
      return colors[key] ? colors[key] : '#083856';
    },
    onCustomerSaved() {
      this.showEditForm = false;
      this.triggerAlertMessage({
        type: 'success',
        message: T('CUSTOMER_CONFIRM_SAVED'),
      });
    },

    getStatisticDisplayValue(widget) {
      //TODO: Add type to widget, base formatter + icon on type?
      //Attempt to apply some formatting for valuta, valuta detection ain't pretty but works for the time being
      //valuta should be formatted using the currency format (if not already pre-formated)

      //Not valuta, return value as is
      if (widget.iconName !== 'currency-euro') return widget.value;
      return this.$format(widget.value, 'currency');
    },
    translateWidgetName(widget) {
      if (!widget) {
        return [];
      }
      const widgetTranslateToPreferedLanguage = T(`${widget.toUpperCase()}`);
      return widgetTranslateToPreferedLanguage;
    },
    translateSection(sections) {
      if (!sections) {
        return [];
      }
      const translateCustomerValue = sections.map(section => {
        let translation = section.displayName;
        translation = T(`${translation.toUpperCase()}`);
        return {...section, displayName: translation};
      });
      return translateCustomerValue;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.nav) {
  border-top: 1px solid;
  color: var(--container-border, #e8e8e8);

  .menu-item {
    padding: 1.25rem 1.75rem calc(1.5rem - 7px) 1.75rem;
  }
}

.small-widgets {
  display: flex;
  column-gap: 0.5rem;

  ::v-deep(.slot-content) {
    width: 17rem;
    height: fit-content;
    display: flex;
    flex-wrap: nowrap;
    column-gap: 0.8rem;

    .small-widget-icon {
      border-radius: 0.6rem;
      width: fit-content;
      height: fit-content;
      padding: 0.6rem;
    }

    .small-widget-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        margin-bottom: 0rem;
      }
    }
  }
}

.general-information {
  .avatar {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    align-items: center;
  }

  .top-info {
    width: 100%;
    display: flex;
    gap: 1.8rem;
  }

  ::v-deep(.slot-content) {
    padding: 1.8rem 1.4rem;
    display: flex;
    flex-wrap: nowrap;

    .ranking-symbol {
      position: relative;
      height: fit-content;

      .customer-icon {
        // always take proper width, even with invalid content
        // ensure alt-text is centered
        display: flex;
        align-items: center;
        justify-content: center;

        width: 5.25rem;
        height: 5.25rem;
        border-radius: 50%;
        border: 0.18rem solid #083856;
      }

      .ranking-symbol {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        right: 0.2rem;
        bottom: 0.4rem;
      }
    }
  }

  .information-left {
    .title,
    .medium {
      margin-bottom: 0.3rem;
    }
  }

  .information-right {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--container-border, #e8e8e8);

    .profile-details {
      display: flex;
      padding-left: 1.8rem;
      column-gap: 2rem;

      .paragraph {
        margin-bottom: 0.1rem;
      }

      p:first-of-type {
        width: 6rem;
      }
    }
  }
}

.general-information-below {
  padding-bottom: 0;

  ::v-deep(.slot-content) {
    padding: 0;

    .nav {
      z-index: 0;
    }
  }
}
.update-button-container {
  top: var(--spacing-md);
  display: flex;
  position: absolute;
  right: var(--spacing-md);
  ::v-deep(.button) {
    width: 100%;
  }
}

:deep .box-below {
  padding-bottom: 0 !important;

  .slot-content {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1280px) {
  ::v-deep(.nav.in-box-below) {
    margin: 0;
  }
}
</style>

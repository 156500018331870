<template>
  <XMultiBox
    class="lastpurchased-container"
    v-if="selectedMenuItem == 1"
    :boxLayout="[
      {
        name: 'content',
        width: '100%',
        contentDisplayType: 'block',
      },
    ]"
    containerHeight="initial"
  >
    <template #contentHeaderTitle>
      <XHeading level="h4">
        {{ $t('CUSTOMER_MANAGER_LAST_PURCHASED') }}
      </XHeading>
    </template>

    <template #contentHeaderButtons>
      <div class="top-buttons-container">
        <XButton v-if="showNewPurchaseScreen" component-style="white" @click="showNewPurchaseScreen = false">
          <XIcon name="clear" size="custom" />
          {{ $t('CANCEL') }}
        </XButton>
        <XButton v-if="showNewPurchaseScreen" component-style="primary-success" @click="saveNewPurchase()">
          <XIcon name="check-circle" size="custom" />
          {{ $t('SAVE') }}
        </XButton>

        <XButton v-else-if="$can.write.customermanager" component-style="white" @click="showNewPurchaseScreen = true">
          <XIcon name="plus-circle" size="custom" />
          {{ $t('CUSTOMER_MANAGER_ADD_PURCHASE') }}
        </XButton>
        <XMenuBox :listTitle="'Voor versie 2'" :listItems="[]" @update:modelValue="[]" />
      </div>
    </template>

    <template #contentBody>
      <div class="content-container" v-if="!showNewPurchaseScreen">
        <div v-for="item in lastPurchaseData" class="content-item-container">
          <div class="top-content-container">
            <div class="image-wrapper">
              <img :src="item.imageURL" alt="Laatste aankoop afbeelding" />
            </div>

            <div class="miscellaneous-container">
              <div class="miscellaneous-backdrop" />

              <div class="miscellaneous-content">
                <XParagraph componentStyle="small">
                  <ul v-for="miscellaneous in item.miscellaneous">
                    <div class="miscellaneaous-diplay-name-spacing">{{ miscellaneous.displayName + ' ' }}:</div>
                    <div class="miscellaneaous-value-spacing">
                      {{ miscellaneous.value }}
                    </div>
                  </ul>
                </XParagraph>
              </div>
            </div>
          </div>

          <div class="bottom-content-container">
            <XParagraph class="description-wrapper" componentStyle="small">
              {{ item.brandName }} - {{ item.externalIdentifier }}
            </XParagraph>
          </div>
        </div>
      </div>
      <div v-else class="add-new-screen">
        <div class="purchase-item">V2.0</div>
      </div>
    </template>
  </XMultiBox>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'CustomerLastPurchased',
  data() {
    return {
      showNewPurchaseScreen: false,
      newPurchase: [],
      showDescription: null,
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customerManager/getCustomer',
      selectedMenuItem: 'customerManager/getSelectedMenuItem',
      lastPurchaseData: 'customerManager/getLastPurchaseData',
    }),
  },
  created() {
    if (this.customer) {
      this.fetchLastPurchaseData(this.customer.id);
    } else {
      this.fetchLastPurchaseData(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions({
      fetchLastPurchaseData: 'customerManager/fetchLastPurchaseData',
    }),
    saveNewPurchase() {
      // todo: lksdf
      this.showNewPurchaseScreen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.lastpurchased-container {
  .top-buttons-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    column-gap: 0.7rem;
    margin-top: 0.8rem;

    ::v-deep(.XMenuBox) {
      margin-right: 0.7rem;
      margin-top: 0.3rem;

      .togglemenu {
        width: 14rem;
      }
    }
  }

  .content-container {
    display: flex;
    column-gap: 1.25rem;
    row-gap: 2rem;
    flex-wrap: wrap;
    padding: 1.25rem;

    .content-item-container {
      display: flex;
      flex-flow: column;

      height: 13.4rem;
      width: 18.4rem;

      //Border
      border: 3px solid var(--container-background, #dde9f2);
      border-radius: 0.5rem;
      border-bottom: none;

      .top-content-container {
        position: relative; //Absolute positioning size parent

        height: 11rem;
        width: 100%;
      }

      .bottom-content-container {
        //Take remaining space
        flex-grow: 1;
      }

      .miscellaneous-container {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        flex-grow: 1;
      }

      .miscellaneous-content {
        position: relative;
        width: 100%;
        top: 1rem;
        left: 0.5rem;

        .miscellaneaous-diplay-name-spacing {
          display: inline-block;
          width: 40%;
          padding-top: 3px;
        }
        .miscellaneaous-value-spacing {
          display: inline-block;
          text-align: left;
          font-weight: bold;
          padding-top: 3px;
          width: 50%;
        }
        ul {
          padding-inline-start: 20px;
        }
      }

      .miscellaneous-backdrop {
        position: absolute;
        top: 0;

        height: 100%;
        width: 100%;

        background-color: var(--container-background, #dde9f2);
        opacity: 0.9;
      }

      .image-wrapper {
        height: 100%;
        width: 100%;

        //Center image
        display: flex;
        justify-content: center;

        img {
          /* Preserve aspect ratio */
          height: auto;
          width: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }

      .description-wrapper {
        height: 100%;

        //Center
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--container-background, #dde9f2);

        //Don't clash with container border
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      &:hover {
        .miscellaneous-container {
          display: flex;
        }
      }
    }
  }
}
</style>
